import React from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SurveyPage from "./survey";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<SurveyPage />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;
