import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useQueryParams from "./utils/query.params";

const buttonContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100vh",
};

const cardStyle = {
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
  backgroundColor: "#d0e9f6",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const buttonStyle = {
  margin: "10px 0",
  padding: "10px 20px",
  backgroundColor: "#f0f0f0",
  color: "#333",
  border: "1px solid #999",
  borderRadius: "4px",
  cursor: "pointer",
  width: "200px",
  textAlign: "center",
  textDecoration: "none",
  fontSize: "16px",
};

const SurveyPage = () => {
  let params = {};
  const [token, setToken] = useState("");
  const [paramActive, setParamActive] = useState(false);

  useQueryParams().forEach((value, key) => {
    params[key] = value;
  });

  useEffect(() => {
    if (params) {
      console.log("params", params);
      if (paramActive === false) setParamActive(true);
    }
  }, [params]);

  useEffect(() => {
    if (params && params.l_token) setToken(params.l_token);
    // eslint-disable-next-line
  }, [paramActive]);

  return (
    <div style={buttonContainerStyle}>
      <h1 style={{ marginBottom: "20px" }}>Survey Test Page</h1>
      <div style={cardStyle}>
        <Link
          to={`https://qa-luca-rf-app.azurewebsites.net/ending?l_status=1&l_token=${token}&SPEC=01`}
          style={buttonStyle}
        >
          Complete
        </Link>
        <Link
          to={`https://qa-luca-rf-app.azurewebsites.net/ending?l_status=2&l_token=${token}`}
          style={buttonStyle}
        >
          Terminate
        </Link>
        <Link
          to={`https://qa-luca-rf-app.azurewebsites.net/ending?l_status=3&l_token=${token}`}
          style={buttonStyle}
        >
          Over Quota
        </Link>
        <Link
          to={`https://qa-luca-rf-app.azurewebsites.net/ending?l_status=4&l_token=${token}`}
          style={buttonStyle}
        >
          Security Terminate
        </Link>
      </div>
    </div>
  );
};

export default SurveyPage;
